import PropTypes from "prop-types"
import React from "react"
import { Flex } from "theme-ui"
import ThemeSwitcher from "../components/themeswitch"

const Header = ({ siteTitle }) => {
  return (
    <header
      sx={{
        position: "sticky",
        top: 0,
        zIndex: 1,
      }}
    >
      <Flex
        sx={{
          maxWidth: "flexContainer",
          py: 3,
          px: [3, 4, 4],
          mx: "auto",
          justifyContent: "flex-end",
        }}
      >
        <ThemeSwitcher />
      </Flex>
    </header>
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
