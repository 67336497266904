import React from "react"
import { useColorMode, Button } from "theme-ui"

const ThemeSwitcher = () => {
  const [colorMode, setColorMode] = useColorMode()
  return (
    <Button variant="primary"
      onClick={e => {
        setColorMode(colorMode === "default" ? "dark" : "default")
      }}
    >
      {colorMode === "default" ? "Light" : "Dark"}
    </Button>
  )
}

export default ThemeSwitcher
