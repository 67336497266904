import React, { useState } from "react"
import { Flex, Container, Styled } from "theme-ui"
import Ticker from "react-ticker"
import PageVisibility from 'react-page-visibility'
import Logo from "../components/logo"

const values = ['CUSTOMERS', 'QUALITY', 'TALENT', 'FLEXIBILITY', 'TRUST', 'REPUTATION']
let i = 0

function iterate() {
  if (i < 5) {i++} else {i = 0}
  return i
}

const Footer = () => {
  const [pageIsVisible, setPageIsVisible] = useState(true)

  const handleVisibilityChange = (isVisible) => {
    setPageIsVisible(isVisible)
  }

  return (
  <footer>
    <PageVisibility onChange={handleVisibilityChange}>
      {pageIsVisible && (
        <Ticker>
          {({ index }) => (
            <>
              <Styled.h4 sx={{ fontSize: [2, 3, 3] }}>
                {values[iterate()]}♥
              </Styled.h4>
            </>
          )}
        </Ticker>
      )}
    </PageVisibility>
    <Container variant="flexContainer" sx={{ mb: [0, 0, 0], pb: [4, 5, 5] }}>
      <Flex
        sx={{
          justifyContent: "space-evenly",
          flexDirection: ["column", "row", "row"],
          alignItems: "center",
          mx: "auto",
          py: 4,
          color: "primary",
        }}
      >
        <div>
          <Styled.a href="mailto:contact@vale.network?subject=Let's talk">
            CONTACT US
          </Styled.a>
        </div>
        <Logo />
        <div>© {new Date().getFullYear()} | VALE INC.</div>
      </Flex>
    </Container>
  </footer>
  )
}

export default Footer

